import styled from 'styled-components';
import {Header} from 'semantic-ui-react';

const PageTitle = styled(Header)`
  display: block;
  max-width: 90%;
  margin: 0 auto 4rem;
  font-size: 64px;
  line-height: 76px;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
`;

export default PageTitle;
