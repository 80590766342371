import React from 'react';

import {Grid} from 'semantic-ui-react';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import SocialMenu from '../components/site/elements/SocialMenu';
import Page from '../components/site/layouts/Page';
import SEO from '../components/shared/SEO/SEO';
import PageTitle from '../components/site/PageTitle';

const AboutImage = () => {
  const data = useStaticQuery(graphql`
    query {
      profile: file(
        relativePath: {eq: "pages/about/angie-ufomata-realtor.jpg"}
      ) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Img
      className="big-bm huge-tm"
      alt="Angie Ufomata - Your Austin Home Realtor"
      fluid={data.profile.childImageSharp.fluid}
    />
  );
};

const AboutPage = () => (
  <Page sidebar={false}>
    <SEO
      title="Angie Ufomata, Realtor"
      description="Austin Realtor and expert with a focus on generational wealth"
      pathname="about/"
      keywords={[
        'Angie Ufomata',
        'Realtor',
        'Generational Wealth',
        'Schools in Texas',
        'Lifestyle and Events in Austin',
      ]}
    />
    <Grid centered>
      <Grid.Column mobile={16} tablet={16} computer={10}>
        <PageTitle as="h1">About Me</PageTitle>
        <AboutImage />
        <article className="text-centered">
          <p>
            <span className="big-text" role="img" aria-label="Waving hand">
              👋
            </span>{' '}
            Hi I&apos;m <b>Angie</b>. I’m a native of Louisiana and have had the
            opportunity to travel around the world as a military “brat”. This
            experience shaped my view of the world and has allowed me to meet
            people from all over the world.
          </p>
          <p>
            I graduated high school in Arizona, and then attended Midwestern
            State University in Wichita Falls, TX. This started my lifelong love
            of the great state of Texas.
          </p>
          <p>
            After college I utilized my Psychology degree and went into the
            mental health field. Later I became a middle school teacher in Round
            Rock ISD for 8 years.
          </p>
          <p>
            Following my career in education I became a business owner. Having
            tasted entrepreneurship with owning a Kickboxing fitness studio and
            a home organizing business, Real Estate naturally became a passion
            of mine.
          </p>
          <p>
            I enjoy helping people realize their dreams in a home. With my vast
            experience in different fields and expertise in helping others I am
            able to truly understand my clients’ needs and give personalized
            high quality service. Whatever you real estate needs are, allow me
            to assist you in achieving them.
          </p>
          <br />
          <div>
            Feel free to text or call me with any real estate questions or needs
            you may have.
          </div>
          <div>
            <div className="text-upper text-bold large-tm">
              Would Love to Connect
            </div>
            <SocialMenu centered />
          </div>
        </article>
      </Grid.Column>
    </Grid>
  </Page>
);

export default AboutPage;
